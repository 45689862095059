import React from "react";
import "./header.css";
import CTA from "./CTA";
import ME from "../../assets/me.png";
import HeaderSocial from "./HeaderSocials";

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        {/* <h5>Hey - Hej</h5> */}
        <h1>QuickPass</h1>
        <h2 className="text-light">
          The Professional's Choice for Event Entry Solutions
        </h2>
        <CTA />
        {/*  <HeaderSocial /> */}

        <div className="me">
          <img src={ME} alt="me" />
        </div>

        <a href="#contact" className="scroll__down">
          Scroll Down
        </a>
      </div>
    </header>
  );
};

export default Header;
