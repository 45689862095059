import React, { useState } from "react";
import "./contact.css";
import { MdOutlineEmail } from "react-icons/md";
import { RiLinkedinLine, RiMessengerLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";
import { useRef } from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const form = useRef();
  const [formBtnMsg, setFormBtnMsg] = useState("Send message");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_wnu1fwb",
      "template_6a9qefi",
      form.current,
      "xmbP2ECU3xjpojepD"
    );
    setFormBtnMsg("Sent successfully");

    e.target.reset();
  };

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Us</h2>

      <div className="container contact__container">
        <div className="contact__options">
          {/* <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>symohannad[at]gmail.com</h5>
            <a href="mailto:symohannad@gmail.com" target="_blank">
              Send a message
            </a>
          </article>
          <article className="contact__option">
            <RiLinkedinLine className="contact__option-icon" />
            <h4>LinkedIn</h4>
            <h5>Muhannad Sayed</h5>
            <a href="https://www.linkedin.com/in/muhannad-sayed-abdullah-2021/">
              Visit the profile
            </a>
          </article> */}
          <article className="contact__option">
            <BsWhatsapp className="contact__option-icon" />
            <h4>WhatsApp</h4>
            <h5>+201157016789</h5>
            <a
              href="https://api.whatsapp.com/send?phone=+46769942609"
              target="_blank"
            >
              Send a message
            </a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS */}
        <div
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <form ref={form} onSubmit={sendEmail} style={{ width: "100%" }}>
            <input
              type="text"
              name="name"
              placeholder="Your Full Name"
              required
              onChange={() => setFormBtnMsg("Send message")}
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              required
            />
            <textarea
              name="message"
              rows="7"
              placeholder="Your Message"
              required
            ></textarea>
            <button type="submit" className="btn btn-primary">
              {formBtnMsg}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
