import React from "react";
import "./about.css";
import ME from "../../assets/me-about.jpg";
import { FaAward } from "react-icons/fa";
import { FiMousePointer, FiTarget, FiUsers, FiPieChart } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";
import { GiTwoCoins } from "react-icons/gi";
import { IoIosSpeedometer } from "react-icons/io";
import { MdOutlineSecurity } from "react-icons/md";
import { MdOutlinePublishedWithChanges } from "react-icons/md";
import { FiLink } from "react-icons/fi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>Benefits of utilizing QuickPass</h2>

      <div className="container about__container">
        {/* <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div> */}
        <p>
          In the digital age, the shift from paper to digital invitations,
          especially through QR codes, presents a compelling case for
          efficiency, security, and engagement. QR code invitations streamline
          the event management process, offering both organizers and attendees a
          multitude of advantages. Below are the key benefits of utilizing
          QuickPass for invitations:
        </p>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <GiTwoCoins className="about__icon" />
              <h5>Cost-Efficiency</h5>
              <small>Saves on printing and mailing.</small>
            </article>
            <article className="about__card">
              <IoIosSpeedometer className="about__icon" />
              <h5>Instant Delivery and RSVPs</h5>
              <small>Facilitates quick communication.</small>
            </article>
            <article className="about__card">
              <MdOutlineSecurity className="about__icon" />
              <h5>Enhanced Security</h5>
              <small>Offers encrypted, guest-specific access.</small>
            </article>
            <article className="about__card">
              <MdOutlinePublishedWithChanges className="about__icon" />
              <h5>Easy Updates and Changes</h5>
              <small>Allows for real-time information updates.</small>
            </article>
            <article className="about__card">
              <FiLink className="about__icon" />
              <h5>Interactive Engagement</h5>
              <small>Links to content for enhanced experience.</small>
            </article>
            <article className="about__card">
              <IoMdCheckmarkCircleOutline className="about__icon" />
              <h5>Seamless Check-in</h5>
              <small>Reduces queues at events.</small>
            </article>
            <article className="about__card">
              <FiPieChart className="about__icon" />
              <h5>Data Collection and Analysis</h5>
              <small>Enables insights into guest preferences.</small>
            </article>
            <article className="about__card">
              <FiTarget className="about__icon" />
              <h5>Accessibility</h5>
              <small>Wide reach via smartphones.</small>
            </article>
          </div>

          {/*  <p>
            My experience ranges from POC to stable performance with well tested
            codes and using CI/CD pipelines for many different types of
            applications and systems, like : web-based, cloud-based, and mobile
            applications with or without using machine learning.
          </p> */}

          <a
            href="#contact"
            className="btn btn-primary"
            style={{ marginTop: "20px" }}
          >
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
